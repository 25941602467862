<template>
  <CButton color="primary" class="float-right" v-on:click="buttonClicked" v-if="data.length">
    {{ this.$t('download_in_csv') }}
    <CIcon name="cil-cloud-download"/>
  </CButton>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    data: Array,
    format: String,
    filename: {
      type: String,
      default: "export"
    },
  },
  methods: {
    buttonClicked() {
      switch (this.format) {
        case "csv":
          this.downloadInCSV();
          break;
        default:
          throw new Error("Cannot find format")
      }
    },

    downloadInCSV() {
      if (!this.data.length) return;

      const isArray = Array.isArray(this.data[0]);
      let csv = isArray ? this.formatArrayToCSV(this.data) : this.formatArrayObjectToCSV(this.data)

      let hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${this.filename}.csv`;
      hiddenElement.click();

      this.$gtag.event('Download', {
        event_category: 'CSV',
        event_label: this.filename,
      })
    },

    formatArrayObjectToCSV(array) {
      let csv = 'sep=;\n';

      array = [Object.keys(array[0])].concat(array)
      csv += array.map(it => Object.values(it).join(';')).join('\n')

      return csv
    },

    formatArrayToCSV(array) {
      let csv = 'sep=;\n';

      array.forEach((row) => {
        csv += row.join(';');
        csv += "\n";
      });

      return csv
    }
  }
}
</script>
