<template>
  <div>
    <CJumbotron>
      <h2 class="font-weight-bold">{{ this.driver.fullname }}</h2>
      <p class="h4 font-weight-light">{{ this.driver.labour_name }}</p>
    </CJumbotron>
    <CRow>
      <CCol col="12 mb-4">
        <h1>{{this.driver.driver_name}}</h1>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.driver.clear_fee | convertCentsToEurosWithCurrency"
          text="Chiffre d'affaires net"
          class="pb-3"
          color="primary">
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.driver.clear_fee / this.driver.success_order_nb | convertCentsToEurosWithCurrency"
          text="Revenu net moyen d'une course"
          class="pb-3"
          color="gradient-info">
        </CWidgetDropdown>
      </CCol>
      <!--      <CCol col="12" lg="3">-->
      <!--        <CWidgetDropdown-->
      <!--          :header="(this.stats.total_mileage ? this.stats.total_mileage : '-') + ' km'"-->
      <!--          text="Kms parcourus en course"-->
      <!--          color="gradient-warning">-->
      <!--          <template #footer>-->
      <!--            <CChartLineSimple-->
      <!--              class="mt-3"-->
      <!--              style="height:70px"-->
      <!--              background-color="rgba(255,255,255,.2)"-->
      <!--              :data-points="[78, 81, 80, 45, 34, 12, 40]"-->
      <!--              :options="{ elements: { line: { borderWidth: 2.5 }}}"-->
      <!--              point-hover-background-color="warning"-->
      <!--              label="Members"-->
      <!--              labels="months"-->
      <!--            />-->
      <!--          </template>-->
      <!--        </CWidgetDropdown>-->
      <!--      </CCol>-->
      <!--      <CCol col="12" lg="3">-->
      <!--        <CWidgetDropdown-->
      <!--          :header="(this.driver.service_score ? this.driver.service_score.toFixed(1) : '-') + '/5'"-->
      <!--          text="Note du jour"-->
      <!--          class="pb-3"-->
      <!--          color="gradient-danger">-->
      <!--          <template #footer>-->
      <!--            <CChartBarSimple-->
      <!--              class="mt-3 mx-3"-->
      <!--              style="height:70px"-->
      <!--              background-color="rgb(250, 152, 152)"-->
      <!--              label="Members"-->
      <!--              labels="months"-->
      <!--            />-->
      <!--          </template>-->
      <!--        </CWidgetDropdown>-->
      <!--      </CCol>-->
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0">{{ this.$t('today') }}</h4>
                <div class="small text-muted">{{ this.$t('last_update') }}: {{ this.lastUpdate | getTimeStringForDate }}</div>
              </CCol>
              <CCol sm="7" class="d-flex justify-content-end align-items-center">
                <CSpinner color="success" grow size="sm" class="mr-2" v-if="this.isLoading"/>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="warning">
                      <small class="text-muted">Nombre de courses proposées (instant ride) <sup>1</sup></small><br>
                      <strong class="h4">{{this.driver.real_dispatch_received_nb}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'acceptation courses immediate</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.real_accepted_nb}} ({{ this.driver.real_accepted_rate.toFixed() }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.real_accepted_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'annulation par le chauffeur pour les courses immediate</span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.real_driver_cancel_nb }} ({{
                          this.driver.real_driver_cancel_rate.toFixed()
                        }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.real_driver_cancel_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="success">
                      <small class="text-muted">Nombre de courses effectuées (instant - booked - 4h 8h) <sup>2</sup></small><br>
                      <strong class="h4">{{this.driver.success_order_nb}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Course immediates <sup>1</sup></span>
                      <span class="ml-auto font-weight-bold">{{this.driver.real_success_nb}}</span>
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Réservations <sup>1</sup></span>
                      <span class="ml-auto font-weight-bold">{{this.driver.subscribe_success_nb}}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="info">
                      <small class="text-muted">Temps de connexion</small><br>
                      <strong class="h4">{{this.driver.long_time + this.driver.order_service_period | secondToTime }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Dont temps en course</span>
                      <span class="ml-auto font-weight-bold">{{this.driver.order_service_period | secondToTime }}</span>
                    </div>
                  </div>
                </ul>
              </CCol>
            </CRow>

            <p>1 - Le nombre de courses terminées aujourd'hui (peuvent avoir été commencées hier)</p>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import { CChartLineSimple, CChartBarSimple } from '../../charts'
import Utils from "@/utils/utils";
import _ from "lodash"

export default {
  name: 'Driver',
  components: {
    CChartLineSimple,
    CChartBarSimple
  },
  mounted() {
    this.getDriver()

    this.refreshDataInterval = setInterval(() => {
      this.getDriver();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.refreshDataInterval);
  },
  data () {
    return {
      isLoading: false,
      lastUpdate: new Date(),
      driver: {
        name: "",
        surname: "",
        clear_fee: 0,
        turnover_fee: 0,
        real_dispatch_received_nb: 0,
        real_accepted_nb: 0,
        real_accepted_rate: 0,
        real_success_nb: 0,
        real_driver_cancel_nb: 0,
        real_driver_cancel_rate: 0,
        subscribe_success_nb: 0,
        success_order_nb: 0,
        long_time: 0,
        pick_up_period: 0,
        order_service_period: 0,
      },
    }
  },
  methods: {
    async getDriver() {
      if (this.isLoading) return;

      this.isLoading = true
      const driver = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}/drivers/${this.$route.params.driverId}`, {
        params: {
          startDate: Utils.formatSimpleDate(new Date()),
          endDate: Utils.formatSimpleDate(new Date())
        }
      })
      this.isLoading = false
      this.lastUpdate = new Date()

      this.driver = driver.data;

      this.driver.fullname = `${_.startCase(this.driver.name)} ${_.upperCase(this.driver.surname)}`

      this.driver.real_accepted_rate = (this.driver.real_accepted_nb / this.driver.real_dispatch_received_nb) * 100
      this.driver.real_accepted_rate = isNaN(this.driver.real_accepted_rate) ? 0 : this.driver.real_accepted_rate
      this.driver.real_driver_cancel_rate = (this.driver.real_driver_cancel_nb / this.driver.real_accepted_nb) * 100
      this.driver.real_driver_cancel_rate = isNaN(this.driver.real_driver_cancel_rate) ? 0 : this.driver.real_driver_cancel_rate
    }
  }
}
</script>
