<template>
  <div>
    <CJumbotron>
      <h2 class="font-weight-bold">{{ this.subcontractor.legal_name }}</h2>
      <p class="h4 font-weight-light">{{ this.subcontractor.legal_lastname }} {{ this.subcontractor.legal_firstname }}</p>
    </CJumbotron>
    <CRow>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.driver.clear_fee | convertCentsToEurosWithCurrency"
          text="Chiffre d'affaires net"
          class="pb-3"
          color="primary">
        </CWidgetDropdown>
      </CCol>
      <CCol col="12" lg="3">
        <CWidgetDropdown
          :header="this.driver.clear_fee / this.driver.success_order_nb | convertCentsToEurosWithCurrency"
          text="Revenu net moyen d'une course"
          class="pb-3"
          color="gradient-info">
        </CWidgetDropdown>
      </CCol>
<!--      <CCol col="12" lg="3">-->
<!--        <CWidgetDropdown-->
<!--          :header="(this.stats.total_mileage ? this.stats.total_mileage : '-') + ' km'"-->
<!--          text="Kms parcourus en course"-->
<!--          color="gradient-warning">-->
<!--          <template #footer>-->
<!--            <CChartLineSimple-->
<!--              class="mt-3"-->
<!--              style="height:70px"-->
<!--              background-color="rgba(255,255,255,.2)"-->
<!--              :data-points="[78, 81, 80, 45, 34, 12, 40]"-->
<!--              :options="{ elements: { line: { borderWidth: 2.5 }}}"-->
<!--              point-hover-background-color="warning"-->
<!--              label="Members"-->
<!--              labels="months"-->
<!--            />-->
<!--          </template>-->
<!--        </CWidgetDropdown>-->
<!--      </CCol>-->
<!--      <CCol col="12" lg="3">-->
<!--        <CWidgetDropdown-->
<!--          :header="(this.todayDriversStats.service_score ? this.todayDriversStats.service_score.toFixed(1) : '-') + '/5'"-->
<!--          text="Note du jour"-->
<!--          class="pb-3"-->
<!--          color="gradient-danger">-->
<!--          <template #footer>-->
<!--            <CChartBarSimple-->
<!--              class="mt-3 mx-3"-->
<!--              style="height:70px"-->
<!--              background-color="rgb(250, 152, 152)"-->
<!--              label="Members"-->
<!--              labels="months"-->
<!--            />-->
<!--          </template>-->
<!--        </CWidgetDropdown>-->
<!--      </CCol>-->
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0">{{ this.$t('today') }}</h4>
                <div class="small text-muted">{{ this.$t('last_update') }}:
                  {{ this.driver.lastUpdate | getTimeStringForDate }}</div>
              </CCol>
              <CCol sm="7" class="d-flex justify-content-end align-items-center">
                <CSpinner color="success" grow size="sm" class="mr-2" v-if="this.driver.isLoading"/>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="warning">
                      <small class="text-muted">Nombre de courses immediate proposées</small><br>
                      <strong class="h4">{{ this.driver.real_dispatch_received_nb }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'acceptation courses immediate</span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.real_accepted_nb }} ({{
                          this.driver.real_accepted_rate.toFixed()
                        }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.real_accepted_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Taux d'annulation par le chauffeur pour les courses immediate</span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.real_driver_cancel_nb }} ({{
                          this.driver.real_driver_cancel_rate.toFixed()
                        }}%)</span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                        class="progress-xs"
                        :value="this.driver.real_driver_cancel_rate"
                        color="warning"
                      />
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="success">
                      <small class="text-muted">Nombre de courses effectuées (instant - booked - 4h 8h) <sup>1</sup></small><br>
                      <strong class="h4">{{ this.driver.success_order_nb }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Course immediates <sup>1</sup></span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.real_success_nb }}</span>
                    </div>
                  </div>
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Réservations <sup>1</sup></span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.subscribe_success_nb }}</span>
                    </div>
                  </div>
                </ul>
              </CCol>

              <CCol sm="12" lg="4">
                <CRow>
                  <CCol sm="12">
                    <CCallout color="info">
                      <small class="text-muted">Temps de connexion</small><br>
                      <strong class="h4">{{ this.driver.long_time + this.driver.order_service_period | secondToTime }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <ul class="horizontal-bars type-2 pl-0">
                  <div class="progress-group">
                    <div class="progress-group-header">
                      <span class="title">Dont temps en course</span>
                      <span class="ml-auto font-weight-bold">{{ this.driver.order_service_period | secondToTime }}</span>
                    </div>
                  </div>
                </ul>
              </CCol>
            </CRow>

            <p>1 - Le nombre de courses terminées aujourd'hui (peuvent avoir été commencées hier)</p>

          </CCardBody>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0">{{ this.$tc('driver', 2) }}</h4>
                <div class="small text-muted">{{ this.$t('last_update') }}:
                  {{ this.driver.lastUpdate | getTimeStringForDate }}</div>
              </CCol>
              <CCol sm="7" class="d-flex justify-content-end align-items-center">
                <CSpinner color="success" grow size="sm" class="mr-2" v-if="this.driver.isLoading"/>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

            <CRow>
              <CCol col="12" md="3">
                <CCallout color="info">
                  <small class="text-muted">Connectés</small><br>
                  <strong class="h4">{{ this.drivers.nbDriverConnected }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="warning">
                  <small class="text-muted">En course</small><br>
                  <strong class="h4">{{ this.drivers.nbDriverInService }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="primary">
                  <small class="text-muted">Disponible</small><br>
                  <strong class="h4">{{ this.drivers.nbDriverAvailable }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="3">
                <CCallout color="secondary">
                  <small class="text-muted">En réservation unique</small><br>
                  <strong class="h4">{{ this.drivers.nbDriverInBookedOnly }}</strong>
                </CCallout>
              </CCol>
            </CRow>
            <CDataTable
              class="mb-0"
              :items="this.drivers.list"
              :fields="this.drivers.driversFields"
              hover
              sorter
              column-filter
              :items-per-page=5
              pagination
              clickableRows
              @row-clicked="rowClicked"
            >
              <td slot="avatar" class="text-center" slot-scope="{item}">
                <div class="c-avatar" :set="imgUrl = 'https://ccglobal.oss-eu-central-1.aliyuncs.com/' + item.photo">
                  <img :src="item.photo === 'default.png' ? 'img/avatar_placeholder.png' : imgUrl " class="c-avatar-img" alt="">
                  <span
                    class="c-avatar-status"
                    :class="`bg-${item.online_key || 'secondary'}`"
                  ></span>
                </div>
              </td>
              <td slot="type_order" class="text-center" slot-scope="{item}">
                {{ typeOrderFormat(item.type_order) }}
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 id="traffic" class="card-title mb-0">{{ this.$t('history') }}</h4>
              </CCol>
              <CCol sm="7" class="d-flex justify-content-end align-items-center">
                <CSpinner color="success" grow size="sm" class="mr-2" v-if="this.history.isLoading"/>
                <DownloadButton v-if="this.history.csvData"
                                v-bind:data="this.history.csvData"
                                v-bind:filename="this.history.csvFilename"
                                format="csv"
                ></DownloadButton>
              </CCol>
            </CRow>
            <CRow class="pt-4">
              <CCol>
                <v-date-picker v-model="history.date" is-range is-required :min-date="new Date(2020, 0, 1)" :max-date='new Date()' color="green" @input="getDriversStatsHistory" show-weeknumbers>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex align-items-center">
                      <CInput
                        label="Start date"
                        placeholder="Select start date"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <CIcon name="cil-arrow-right" class="mt-3 ml-2 mr-2" />
                      <CInput
                        label="End date"
                        placeholder="Select end date"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CChartLine
                  style="height:250px"
                  :datasets="this.history.ridesChartValues"
                  :labels="this.history.ridesChartLabels"
                  :options="this.history.ridesChartOptions"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="2">
                <h4 class="card-title mb-0">{{ this.$tc('order', 2) }}</h4>
                <div class="small text-muted">{{ this.$t('last_update') }}:
                  {{ this.orders.lastUpdate | getTimeStringForDate }}</div>
              </CCol>
              <CCol sm="10" class="d-flex justify-content-end align-items-center">
                <CSpinner color="success" grow size="sm" class="mr-2" v-if="this.orders.isLoading"/>
                <DownloadButton v-if="this.orders.csvData"
                                v-bind:data="this.orders.csvData"
                                v-bind:filename="this.orders.csvFilename"
                                format="csv"
                ></DownloadButton>
              </CCol>
            </CRow>
            <CRow class="pt-4">
              <CCol>
                <v-date-picker v-model="orders.date" is-range is-required :min-date="new Date(2020, 0, 1)" :max-date='new Date()' color="green" @input="getSuccessOrders" show-weeknumbers>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex align-items-center">
                      <CInput
                        label="Start date"
                        placeholder="Select start date"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <CIcon name="cil-arrow-right" class="mt-3 ml-2 mr-2" />
                      <CInput
                        label="End date"
                        placeholder="Select end date"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CDataTable
                  class="mb-0"
                  :items="this.orders.list"
                  :fields="this.orders.fields"
                  small
                  :items-per-page=10
                  pagination
                >
                  <td slot="avatar" class="text-center" slot-scope="{item}">
                    <div class="c-avatar" :set="imgUrl = 'https://ccglobal.oss-eu-central-1.aliyuncs.com/' + item.photo">
                      <img :src="item.photo === 'default.png' ? 'img/avatar_placeholder.png' : imgUrl " class="c-avatar-img" alt="">
                    </div>
                  </td>
                  <td slot="driver" slot-scope="{item}">
                    <div>{{ item.name }} {{ item.surname }}</div>
                    <div class="small text-muted">
                    <span>
                      {{ $t('service_type') }}
                    </span> - {{ item.service_type }}
                    </div>
                  </td>
                  <td slot="order_type" slot-scope="{item}">
                    <div>{{ item.order_type }}</div>
                  </td>
                  <td slot="addr" slot-scope="{item}">
                    <div><CIcon name="cil-flag-alt" class="text-success"/> {{item.start_addr}}</div>
                    <div><CIcon name="cil-flag-alt" class="text-danger"/> {{item.end_addr}}</div>
                  </td>
                  <td slot="total_fee" slot-scope="{item}">
                    <div>{{item.total_fee | convertAmountToEuros }}</div>
                  </td>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash'
import {CChartBarSimple, CChartLineSimple} from "@/views/charts";
import DownloadButton from "@/views/buttons/DownloadButton";
import {CChartLine} from "@coreui/vue-chartjs";
import { DriverEnum, OrderEnum } from "@caocao-mobility/ccmp-enum"
import Utils from "@/utils/utils";

export default {
  name: 'Subcontractor',
  components: {
    CChartLineSimple,
    CChartBarSimple,
    CChartLine,
    DownloadButton
  },
  mounted() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 30)
    endDate.setDate(endDate.getDate())

    this.history.date = { start: startDate, end: endDate }
    this.orders.date = { start: endDate, end: endDate }

    this.getSubcontractorInfo()
    this.getTodayDriversStats()
    this.getDriversStatsHistory()
    this.getSuccessOrders()

    this.refreshDataInterval = setInterval(() => {
      this.getTodayDriversStats();
      this.getSuccessOrders()
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.refreshDataInterval);
  },
  methods: {
    async getSubcontractorInfo() {
      const subcontractor = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}`)

      subcontractor.data.legal_lastname = _.startCase(subcontractor.data.legal_lastname)
      subcontractor.data.legal_firstname = _.upperCase(subcontractor.data.legal_firstname)

      this.subcontractor = subcontractor.data
    },

    async getTodayDriversStats() {
      if (this.driver.isLoading) return;

      this.driver.isLoading = true
      const todayDriversStats = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}/history`, {
        params: {
          startDate: Utils.formatSimpleDate(new Date()),
          endDate: Utils.formatSimpleDate(new Date())
        }
      })
      const drivers = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}/drivers`)
      this.driver.isLoading = false
      this.driver.lastUpdate = new Date()

      const merged = _.merge(_.keyBy(todayDriversStats.data, 'driver_no'), _.keyBy(drivers.data, 'driver_no'));
      todayDriversStats.data = _.values(merged);
      todayDriversStats.data = _.filter(todayDriversStats.data, (driver) => (driver["driver_status"] === 12));

      _.forEach(todayDriversStats.data, (driver) => {
        driver.clear_fee_formated = this.$options.filters.convertCentsToEurosWithCurrency(driver.clear_fee)
      });

      this.updateDriversTable(todayDriversStats.data)

      this.driver.turnover_fee = _.sumBy(todayDriversStats.data, 'turnover_fee')
      this.driver.clear_fee = _.sumBy(todayDriversStats.data, 'clear_fee')

      this.driver.real_dispatch_received_nb = _.sumBy(todayDriversStats.data, 'real_dispatch_received_nb')
      this.driver.real_accepted_nb = _.sumBy(todayDriversStats.data, 'real_accepted_nb')
      this.driver.real_accepted_rate = (this.driver.real_accepted_nb / this.driver.real_dispatch_received_nb) * 100
      this.driver.real_accepted_rate = isNaN(this.driver.real_accepted_rate) ? 0 : this.driver.real_accepted_rate
      this.driver.real_success_nb = _.sumBy(todayDriversStats.data, 'real_success_nb')
      this.driver.real_driver_cancel_nb = _.sumBy(todayDriversStats.data, 'real_driver_cancel_nb')
      this.driver.real_driver_cancel_rate = (this.driver.real_driver_cancel_nb / this.driver.real_accepted_nb) * 100
      this.driver.real_driver_cancel_rate = isNaN(this.driver.real_driver_cancel_rate) ? 0 : this.driver.real_driver_cancel_rate

      this.driver.subscribe_success_nb = _.sumBy(todayDriversStats.data, 'subscribe_success_nb')

      this.driver.success_order_nb = _.sumBy(todayDriversStats.data, 'success_order_nb')

      this.driver.long_time = _.sumBy(todayDriversStats.data, 'long_time')
      this.driver.pick_up_period = _.sumBy(todayDriversStats.data, 'pick_up_period')
      this.driver.order_service_period = _.sumBy(todayDriversStats.data, 'order_service_period')
    },

    updateDriversTable(drivers) {
      _.forEach(drivers, (driver) => {
        driver.fullname = `${_.startCase(driver.name)} ${_.upperCase(driver.surname)}`
        driver.online_key = this.getOnlineStatus(driver.online)
      })

      this.drivers.nbDriverAvailable = _.filter(drivers, (o) => o["online"] === DriverEnum.Online.ONLINE).length
      this.drivers.nbDriverInService = _.filter(drivers, (o) => o["online"] === DriverEnum.Online.IN_SERVICE).length
      this.drivers.nbDriverConnected = this.drivers.nbDriverAvailable + this.drivers.nbDriverInService
      this.drivers.nbDriverInBookedOnly = _.filter(drivers, (o) => (o["type_order"] === OrderEnum.OrderType.APPOINTMENT)).length

      this.drivers.list = drivers
    },

    rowClicked(driver) {
      this.$router.push(`${this.$route.params.subcontractorId}/drivers/${driver.driver_no}`);
    },

    async getDriversStatsHistory() {
      if (this.history.isLoading) return;

      this.history.isLoading = true
      const history = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}/history`, {
        params: {
          startDate: Utils.formatSimpleDate(this.history.date.start),
          endDate: Utils.formatSimpleDate(this.history.date.end)
        }
      })

      this.history.isLoading = false

      this.clearDriversStatsHistory()
      this.updateDriversStatsHistoryChart(history)
      this.updateDriversStatsHistoryCSV(history)
    },

    updateDriversStatsHistoryChart(history) {
      let statsGroupedByDate = _.groupBy(history.data, 'statistics_date')

      let instantRideSuccessHistory = { label: this.$t('real_time'), key: 'real_success_nb', borderColor: 'green', data: [] }
      let subscribeRideSuccessHistory = { label: this.$t('appointment'), key: 'subscribe_success_nb', borderColor: 'blue', data: [] }

      statsGroupedByDate = Object.keys(statsGroupedByDate).sort().reduce((obj, key) => {
          obj[key] = statsGroupedByDate[key];
          return obj;
        },
        {}
      );

      for (const [date, values] of Object.entries(statsGroupedByDate)) {
        const dateFormated = this.$options.filters.getShortMonthDayStringForDate(date)
        this.history.ridesChartLabels.push(dateFormated);

        instantRideSuccessHistory.data.push(_.sumBy(values, instantRideSuccessHistory.key));
        subscribeRideSuccessHistory.data.push(_.sumBy(values, subscribeRideSuccessHistory.key));
      }

      this.history.ridesChartValues.push(instantRideSuccessHistory)
      this.history.ridesChartValues.push(subscribeRideSuccessHistory)
    },

    updateDriversStatsHistoryCSV(drivers) {
      let statsGroupedByDriver = _.groupBy(drivers.data, 'driver_no')

      _.forEach(statsGroupedByDriver, (driverStats, driverNo) => {
        statsGroupedByDriver[driverNo] = Utils.addMissingDatesForPeriod(driverStats, Utils.formatSimpleDate(this.history.date.start), Utils.formatSimpleDate(this.history.date.end), 'statistics_date')
      });

      let reportCSV = [];
      for (const [key, driver] of Object.entries(statsGroupedByDriver)) {

        let {
          driver_no: driverNo,
          name: driverFirstname,
          surname: driverLastname
        } = _.find(driver, 'driver_no');

        driverFirstname = _.startCase(driverFirstname)
        driverLastname = _.upperCase(driverLastname)

        let realAcceptanceRateForPeriod = ((_.sumBy(driver, "real_accepted_nb") / _.sumBy(driver, "real_dispatch_received_nb")).toFixed(2)) * 100
        let realCancelRateForPeriod = ((_.sumBy(driver, "real_driver_cancel_nb") / _.sumBy(driver, "real_accepted_nb")).toFixed(2)) * 100
        realAcceptanceRateForPeriod = isNaN(realAcceptanceRateForPeriod) ? 0 : realAcceptanceRateForPeriod
        realCancelRateForPeriod = isNaN(realCancelRateForPeriod) ? 0 : realCancelRateForPeriod

        let driverRateForPeriod = _.mean(_.compact(_.map(driver, "service_score"))).toFixed(2)
        driverRateForPeriod = isNaN(driverRateForPeriod) ? 0 : driverRateForPeriod

        let row = [
          ["ID Chauffeur", "Chauffeur", "Données", ..._.map(driver, "statistics_date"), "Total"],
          [driverNo, `${driverFirstname} ${driverLastname}`, "GMV", ..._.map(driver, (d) => this.$options.filters.convertCentsToAmount(d.clear_fee)), this.$options.filters.convertCentsToAmount(_.sumBy(driver, "clear_fee"))],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Temps de connexion (h)", ..._.map(driver, (d) => this.$options.filters.secondToHours(d.online_time)), this.$options.filters.secondToHours(_.sumBy(driver, "online_time"))],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Taux d'acceptation", ..._.map(driver, (d) => this.$options.filters.toPercent(d.real_accepted_rate)), `${realAcceptanceRateForPeriod} %`],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Taux d'annulation", ..._.map(driver, (d) => this.$options.filters.toPercent(d.real_driver_cancel_rate)), `${realCancelRateForPeriod} %`],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Note du jour", ..._.map(driver, "service_score"), driverRateForPeriod],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates proposées", ..._.map(driver, "real_dispatch_received_nb"), _.sumBy(driver, "real_dispatch_received_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates acceptées", ..._.map(driver, "real_accepted_nb"), _.sumBy(driver, "real_accepted_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses immédiates réalisées", ..._.map(driver, "real_success_nb"), _.sumBy(driver, "real_success_nb")],
          [driverNo, `${driverFirstname} ${driverLastname}`, "Courses Réservations réalisées", ..._.map(driver, "subscribe_success_nb"), _.sumBy(driver, "subscribe_success_nb")]
        ]

        reportCSV = [...reportCSV, ...row]
      }

      this.history.csvData = reportCSV
      this.history.csvFilename = `history_${Utils.formatSimpleDate(this.history.date.start)}_${Utils.formatSimpleDate(this.history.date.end)}`
    },

    clearDriversStatsHistory() {
      this.history.csvData = null
      this.history.csvFilename = ""
      this.history.ridesChartLabels = []
      this.history.ridesChartValues = []
    },

    async getSuccessOrders() {
      if (this.orders.isLoading) return;

      const startDate = Utils.formatSimpleDate(this.orders.date.start)
      const endDate = Utils.formatSimpleDate(this.orders.date.end)

      this.orders.isLoading = true
      const orders = await axios.get(`/subcontractors/${this.$route.params.subcontractorId}/orders`, {
        params: {
          startDate,
          endDate
        }
      })
      this.orders.isLoading = false
      this.orders.lastUpdate = new Date()

      _.forEach(orders.data, (order) => {
        order.name = _.startCase(order.name)
        order.surname = _.upperCase(order.surname)
        order.total_fee = this.$options.filters.convertCentsToAmount(order.total_fee)
        order.order_type = this.translateOrderTypeToText(order.order_type)
        order.service_type = this.translateServiceTypeToText(order.service_type)
      });

      this.orders.list = orders.data

      this.orders.csvFilename = `orders_${startDate}_${endDate}`
      this.orders.csvData = _.map(orders.data, (order) => _.omit(order, ['photo']))
    },

    getOnlineStatus(value) {
      let color
      // info
      if (value === 1 || value === 2) color = 'success'
      else if (value === 3) color = 'danger'
      else if (value === 4) color = 'warning'
      else color = 'secondary'

      return color
    },

    translateOrderTypeToText(orderType) {
      const translateKey = this.$options.filters.formatOrderType(orderType)
      return this.$t(translateKey)
    },

    translateServiceTypeToText(serviceType) {
      const translateKey = this.$options.filters.formatServiceType(serviceType)
      return this.$t(translateKey)
    },

    typeOrderFormat(value) {
      let typeOrder

      if (value === 1) typeOrder = 'ALL'
      else if (value === 2) typeOrder = 'BOOKED_ONLY'
      else if (value === 3) typeOrder = 'REALTIME_ONLY'
      else typeOrder = null

      return typeOrder
    }
  },
  data () {
    return {
      subcontractor: {
        legal_name: "",
        legal_firstname: "",
        legal_lastname: ""
      },
      driver: {
        isLoading: false,
        lastUpdate: new Date(),
        turnover_fee: 0,
        real_dispatch_received_nb: 0,
        real_accepted_nb: 0,
        real_accepted_rate: 0,
        real_success_nb: 0,
        real_driver_cancel_nb: 0,
        real_driver_cancel_rate: 0,
        subscribe_success_nb: 0,
        success_order_nb: 0,
        long_time: 0,
        pick_up_period: 0,
        order_service_period: 0,
      },
      drivers: {
        isLoading: false,
        list: [],
        driversFields: [
          { key: 'avatar', filter: false, _classes: 'text-center', _style: 'width:80px' },
          { key: 'fullname', label: "Nom", sorter: false },
          { key: 'clear_fee_formated', label: "CA Net", sorter: true, filter: false, _classes: "text-center", _style: 'width:100px', },
          { key: 'success_order_nb', _classes: "text-center", _style: 'width:100px', filter: false, sorter: true },
          { key: 'driver_cancel_nb', _classes: "text-center", _style: 'width:150px', filter: false, sorter: true },
          { key: 'type_order', _classes: "text-center", label: "Mode", _style: 'width:100px', filter: false }
        ],
        nbDriverInService: 0,
        nbDriverConnected: 0,
        nbDriverInBookedOnly: 0
      },
      history: {
        isLoading: false,
        date: {},
        csvData: [],
        csvFilename: "",
        ridesChartValues: [],
        ridesChartLabels: [],
        ridesChartOptions: {
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        },
        acceptanceRateChartValues: [],
        acceptanceRateChartLabels: [],
        acceptanceRateChartOptions: {
          elements: {
            line: {
              borderWidth: 2
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      },
      orders: {
        isLoading: false,
        lastUpdate: new Date(),
        date: {},
        csvData: [],
        csvFilename: "",
        list: [],
        fields: [
          { key: 'avatar', label: '', _classes: 'text-center', _style: 'width:36px' },
          { key: 'driver' },
          { key: 'order_type' },
          { key: 'addr' },
          { key: 'total_fee', label: 'CA' },
          { key: 'get_on_time' },
          { key: 'get_off_time' },
        ]
      }
    }
  }
}
</script>

<style scoped>
  .c-avatar-img {
    max-height: 100%;
  }
</style>
